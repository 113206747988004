<template>
    <div class="cart-content">
        <div class="section">
            <el-breadcrumb separator=">">
                <el-breadcrumb-item :to="{ path: '>' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>购物车</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div v-if="goodList.length >0">
             <div class="section1">
                <el-row>
                    <el-col :span="2">
                        <el-checkbox v-model="check1" @change="selectAll">全选</el-checkbox>
                    </el-col>
                    <el-col :span="6">
                        商品信息
                    </el-col>
                    <el-col :span="3">
                        单价
                    </el-col>
                    <el-col :span="3">
                        数量
                    </el-col>
                    <el-col :span="6">
                        金额（元）
                    </el-col>
                    <el-col :span="3">
                        操作
                    </el-col>
                </el-row>
            </div>

            <div class="section2">
                <el-row v-for="item in goodList" :key="item.id" class="shopcart">
                    <el-col :span="1" style="margin-left:10px;">
                        <div class="good-info">
                            <el-checkbox v-model="item.checked" @change="goodChange"></el-checkbox>
                        </div>
                    </el-col>
                    <el-col :span="7">
                        <div class="good-info" style="justify-content:left;">
                            <img :src="item.imgurl" class="good-img"/>
                            <p>{{item.pro_name}}</p>
                        </div>
                        
                    </el-col>
                    <el-col :span="3">
                        <div class="good-info">
                            <p>￥{{item.price}}</p>
                        </div>
                    </el-col>
                    <el-col :span="3">
                        <div class="good-info">
                            <el-input-number v-model="item.num" :min="1" :max="10" @change="handleChange" size="mini"/>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="good-info">
                            <p class="sec-price">￥{{(item.price*item.num).toFixed(2)}}</p>
                        </div>
                    </el-col>
                    <el-col :span="3">
                        <div class="good-info last-section">
                            <el-button type="text" @click="collectgood(item.id)">移到我的收藏</el-button>
                            <el-button type="text" @click="deleteCart(item.id)">删除</el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div class="to-payBox">
                <el-row style="width: 1200px; z-index: 99;" :style="{position: is_position?'fixed':'',bottom: is_position?'0':''}">
                    <el-col :span="2">
                        <div class="info">
                            <el-checkbox label="全选" v-model="check1" @change="selectAll"></el-checkbox>
                        </div>
                    </el-col>
                    <el-col :span="10">
                        <div class="info" style="justify-content:left;">
                            <span style="cursor: pointer;" @click="openMask()">删除选中商品</span>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="info">
                            已选商品<span class="red">{{checkNum}}</span>件&nbsp;&nbsp;
                            总价(不含运费)：<span class="red">￥{{totalPrice}}</span>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="info">
                            <input class="topayBtn" type="button" value="去结算" @click="toPay">
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>

        <div v-else class="empty">
            <img src="@/assets/image/empty_cart.png" />
            <p>购物车内暂时还没有商品，赶紧去逛逛~<br/>或者先登录同步之前加入的商品</p>
            <div class="btn" @click="toHome">
                去逛逛
            </div>
        </div>
       

        <el-dialog width="30%" v-model="diavisible" :show-close="false">
            <div class="message">
                <img src="@/assets/image/warn.png" />
                <div class="title">
                    {{deleFlag?'确认要删除该商品吗？':'确认要删除选中的商品吗？'}}
                </div>
            </div>
            <template #footer>
                <div class="foot-con">
                    <el-button @click="diavisible =false" >取消</el-button>
                    <el-button type="danger" @click="ensureDelete(d_good_id)">确定</el-button>
                </div>
            </template>
        </el-dialog>


        <el-dialog width="35%" v-model="diavisible1" title="添加商品">
            <div class="message-box">
                <div class="top">
                    <div class="t-left">
                        <img :src ="photo_x" />
                    </div>
                    <div class="t-right">
                        <div class="title">{{name}}</div>
                        <p>￥{{price}}</p>
                    </div>
                </div>
            </div>
            <template #footer>
                <div class="foot-con">
                    <el-button @click="diavisible1 =false" >取消</el-button>
                    <el-button type="danger" @click="ensureDelete(d_good_id)">确定</el-button>
                </div>
            </template>
        </el-dialog>


        <el-dialog width="30%" v-model="diavisible2" :show-close="false">
            <div class="message">
                <img src="@/assets/image/warn.png" />
                <div class="title">
                    确定要移到我的收藏吗？
                </div>
                <p>商品移入我的收藏后，将不在购物车显示</p>
            </div>
            <template #footer>
                <div class="foot-con">
                    <el-button @click="diavisible2 =false" >取消</el-button>
                    <el-button type="danger" @click="ensureMove(collectId)">确定</el-button>
                </div>
            </template>
        </el-dialog>

        

        <div class="recommend-con">
            <h3>为你推荐</h3>
            <div class="list-con">
                <div class="item" v-for="item in recommendList" :key="item.id" @click="todetail(item.id)">
                    <img :src="item.imgurl" class="good-img"/>
                    <div class="title">{{item.product_title}}</div>
                    <div class="bottom">
                        <span>¥{{item.price}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { onMounted,ref,computed, reactive, toRefs,onUnmounted,watchEffect } from 'vue'
import {getData} from '@/utils/req'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import {useRouter} from 'vue-router'
import $ from 'jquery'
export default {
    setup(){
        let pc_user = ref('')
        let recommendList = ref('')
        let goodList = ref([])   
        let diavisible = ref(false)
        let diavisible1 = ref(false)
        let diavisible2 = ref(false)
        let d_good_id = ref('')
        let is_position = ref(false)
        const router = useRouter()
        const store = useStore()
        let checkNum = ref(0)
        let totalPrice = ref(0)
        let check1 = ref(false)
        let collectId = ref('')
        let deleFlag = ref(false)

        let state = reactive({
            name:'',
            photo_x:'',
            price:'',
            cat_name:'',
            num:1
        })
        onMounted(()=>{
            pc_user.value = JSON.parse(localStorage.getItem('pc_user'))
            axios()
            getRecommend()
            window.addEventListener('scroll', handleScroll,true)
        })
        onUnmounted(()=>{
            window.removeEventListener('scroll', handleScroll)
        })
        //监听购物车数量选择判断状态
        watchEffect(()=>{
            let flag = true;
            goodList.value.forEach(item=>{
                if(!item.checked){
                    flag = false;
                }
            })
            check1.value = flag;
        })
        //回到首页
        const toHome = ()=>{
            /**
             * router 打开另外一个新窗口
             */
            const { href } = router.resolve({
                path: '/',
            });
            window.open(href, "_blank");
        }
        const handleScroll = ()=>{
            let top = $('.to-payBox').offset().top
            if (top >= $(window).scrollTop() && top < ($(window).scrollTop() + $(window).height()-70)) {
                is_position.value = false
            }else{
                is_position.value = true
            }
        }
        const affixheight = computed(()=>{
            return window.screen.height- 195
        })
        const axios =()=>{
            const data ={
                module: 'app_pc',
                action: 'cart',
                m: 'index',
                access_id:pc_user.value.access_id
            }
            getData(data).then(res=>{
                if(res.code == 200){
                    if(res.data.list.length >0){
                        goodList.value = res.data.list[0].list
                        goodList.value.forEach(item=>{
                            item.checked = false
                        })
                    }
                    else{
                        goodList.value = [];
                    }
                }
            })
        }

        const toPay = ()=>{
            let car_id = []
            goodList.value.forEach(item=>{
                if(item.checked == true){
                    car_id.push(item.id)
                }
            })

            if(car_id.length == 0){
                ElMessage.warning({
                    message:'请先选择商品',
                    duration:1500
                })
                return;
            }
            router.push({
                path:'/paydetail',
                query:{
                    cart_id:car_id.join(',')
                }
            })
        }
        const ensureDelete = (id) =>{
            const data ={
                module: 'app_pc',
                action: 'cart',
                m: 'delcart',
                access_id: pc_user.value.access_id,
                car_id: id
            }
            getData(data).then(res=>{
                if(res.code ==200){
                    ElMessage.success({
                        message:'删除成功',
                        duration:1500
                    })
                    axios()
                    diavisible.value = false
                    store.dispatch("updateCart");
                }
            })
        }
        //全选
        const selectAll = (e) =>{
            check1.value = e
            if(e == true){
                let index = 0
                let price = 0
                goodList.value.forEach(item=>{
                    index+= item.num;
                    item.checked = true
                    price += (item.price*item.num)
                })
                checkNum.value =index
                totalPrice.value = price.toFixed(2)
            }
            else{
                goodList.value.forEach(item=>{
                    item.checked = false
                })
                checkNum.value = 0
                totalPrice.value = 0
            }
        }
        //收藏商品
        const collectgood = (id) =>{
            diavisible2.value = true
            collectId.value = id
        }
        //点击数量时更新总价格
        const handleChange =() =>{
            let price = 0
            let index = 0
            goodList.value.forEach(item=>{
                if(item.checked == true){
                    index += item.num
                    price += (item.price*item.num)
                }
            })
            checkNum.value =index
            totalPrice.value = price.toFixed(2)
        }
        const ensureMove = (id) =>{
            const data = {
                module: 'app_pc',
                action: 'cart',
                m: 'to_Collection',
                access_id: pc_user.value.access_id,
                car_id: id
            }
            getData(data).then(res =>{
                if(res.code ==200){
                    ElMessage.success({
                        message:'加入收藏成功',
                        duration:1500
                    })
                    diavisible2.value = false
                    axios()
                    store.dispatch("updateCart")
                }
            })
        }
        const deleteCart = (id) =>{
            diavisible.value = true
            deleFlag.value = true;
            d_good_id.value = id
        }
        const getAttribute = (id) =>{
            const data ={
                module: 'app_pc',
                action: 'product',
                m: 'index',
                access_id: pc_user.value.access_id,
                pro_id: id
            }
            getData(data).then(res=>{
                if(res.code ==200){
                    state.name = res.data.pro.name
                    state.photo_x = res.data.pro.photo_x
                    state.price = res.data.pro.price
                    state.cat_name = res.data.pro.cat_name
                }
            })
        }
        const getRecommend =()=>{
            const data ={
                module: 'app_pc',
                action: 'product',
                m: 'recommend',
                access_id:pc_user.value.access_id
            }
            getData(data).then(res=>{
                if(res.code ===200){
                    recommendList.value = res.data
                }
            })
        }
        const todetail = (id)=>{
            const { href } = router.resolve({
                path: '/homedetail',
                query: {
                    good_id:id
                }
            });
            window.open(href, "_blank");
        }

        const addCart =(item)=>{
            diavisible1.value = true
            
            getAttribute(item.id)
        }
        const goodChange = () =>{
            let index = 0
            let price = 0
            goodList.value.forEach(item=>{
                if(item.checked == true){
                    index+= item.num;
                    price += (item.price*item.num)
                }
                
            })
            checkNum.value =index
            totalPrice.value = price.toFixed(2)
        }
        const openMask = () =>{
            diavisible.value = true;
            //多选时提示
            deleFlag.value = false;
        }
        return {
            check1,       //全选标志符
            affixheight,
            is_position,
            d_good_id,
            collectId,
            diavisible,
            diavisible1,
            diavisible2,
            totalPrice,
            recommendList,
            goodList,        //购物车列表数据
            checkNum,
            deleFlag,        //多选或者单选提示关键词
            ...toRefs(state),
            deleteCart,
            ensureDelete,
            todetail,
            addCart,
            getAttribute,
            collectgood,
            ensureMove,
            selectAll,
            handleChange,
            toPay,
            goodChange,
            openMask,
            toHome
        }
    }
}
</script>
<style scoped lang="scss">
    .cart-content{
        width: 1200px;
        margin: 30px auto 0;
        .section1{
            margin-top: 30px;
            background-color: rgb(246, 246, 246);
            height: 44px;
            color: #999999;
            background: #F6F6F6;
            font-size: 14px;
            text-align: center;
            line-height: 44px;
            .space{
                width: 100%;
                height: 70px;
            }
            .good-img{
                width: 70px;
                height: 70px;
                border: 1px solid #EEEEEE;
                margin-left: 5px;
                margin-right: 12px;
            }
        }
        .section2{
            margin-top: 30px;
            .good-info{
                height: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
                .good-img{
                    width: 70px;
                    height: 70px;
                    border: 1px solid #eee;
                    margin-right: 12px;
                }
                p{
                    font-size: 14px;
                    color: #020202;
                }
                .sec-price{
                    color: #D4282D;
                    font-weight: bold;
                }
            }
            .last-section{
                display: flex;
                align-items: center;
                flex-direction: column;
            }
            .shopcart{
                border: 1px solid #eee;
                height: 120px;
            }
        }


        .to-payBox{
            margin-top: 27px;
            .el-row{
                background: #eee;
            }
            .info{
                font-size: 14px;
                color: #020202;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 70px;
                .red{
                    color: #D4282D;
                    font-size: 20px;
                    font-weight: bold;
                    margin: 0px 3px;
                }
            }
            .topayBtn{
                width: 100%;
                height: 100%;
                background: #D4282D;
                color: #ffffff;
                border: none;
                font-size: 20px;
                cursor: pointer;
                text-align: center;
            }
        }
        .recommend-con{
            width: 100%;
            margin-top: 70px;
            margin-bottom: 70px;
            h3{
                color: #020202;
                font-size: 16px;
                font-weight: bold;
                line-height: 20px;
            }
            .list-con{
                display: flex;
                align-items: center;
                margin-top: 15px;
                .item{
                    text-align: center;
                    width: 288px;
                    border:1px solid #eee;
                    cursor: pointer;
                    .good-img{
                        width: 100%;
                    }
                    .title{
                        font-weight: bold;
                        color: #020202;
                        font-size: 14px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin-top: 30px;
                        margin-bottom: 10px;
                        padding: 0 20px;
                    }
                    .bottom{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 30px;
                        span{
                            color: #D4282D;
                            font-size: 14px;
                            vertical-align: middle;
                        }
                        .cart{
                            width: 24px;
                            height: 24px;
                            background: #EEEEEE;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: 5px;
                            .cart-img{
                                width: 16px;
                                
                            }
                        }
                    }
                }
                .item:not(:last-child){
                    margin-right: auto;
                }
                .item:hover{
                    background: #eee;
                }
            }
        }

        .empty{
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            img{
                width: 174px;
                height: 181px;
                margin-top: 80px;
                margin-bottom: 19px;
            }
            p{
                font-size: 16px;
                line-height: 20px;
                color: #888888;
            }
            .btn{
                width: 112px;
                height: 40px;
                border: 1px solid rgba(212,40,45,1);
                cursor: pointer;
                text-align: center;
                line-height: 40px;
                margin-top: 29px;
                background: #ffffff;
                color: #D4282D;
                margin-left: 10px;
            }
        }
    }

    .message{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title{
            font-size: 16px;
            line-height: 16px;
            color: #020202;
            margin-top: 16px;
        }
        p{
            color: #999999;
            font-size: 12px;
            line-height: 12px;
            margin-top: 10px;
        }
        img{
            width: 36px;
            height: 36px;
            margin:36px auto 0;
        }
        
    }

    .message-box{
        padding: 50px 0 24px;
        margin: 0 40px 24px;
        border-bottom: 1px solid #EEEEEE;
        .top{
            display: flex;
            .t-left{
                width: 78px;
                height: 78px;
                margin-right: 16px;
                img{
                    width: 100%;
                }
            }
            .t-right{
                .title{
                    font-size: 16px;
                    color: #020202;
                    max-width: 400px;
                }
                p{
                    font-size: 16px;
                    color: #D4282D;
                    line-height: 12px;
                    margin-top: 17px;
                }
            }
        }
    }
    .foot-con{
        padding-bottom: 30px;
    }
    ::v-deep .el-dialog__body{
        padding:0px;
    }
    ::v-deep .el-dialog__header{
        padding:0px;
    }
    ::v-deep .el-dialog__footer{
        text-align: center;
        padding-top: 24px;
    }
    ::v-deep .el-button{
        width: 112px;
        height: 36px;
        border-radius: 0px;
        min-height: 0px;
    }
    ::v-deep .el-button--text{
        width: 0px;
        height: 0px;
        font-size: 14px;
        color: #666;
    }
    ::v-deep .el-dialog__title{
        padding: 22px 0 22px 20px;
        display: block;
        font-size: 16px;
        line-height: 16px;
        color: #000000;
        font-weight: bold;
    }
    ::v-deep .el-dialog__header{
        border-bottom: 1px solid #e6e6e6;
    }
</style>

